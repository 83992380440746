import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ApiModule, Configuration, WhoamiService} from "../openapi-generated";
import {provideHttpClient} from "@angular/common/http";
import {ServiceWorkerModule} from '@angular/service-worker';
import {PwaInstallService} from "./services/pwa-install.service";
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from "i18next";
import {LanguageService} from "./modules/i18n/language.service";
import {i18nEn} from "./modules/i18n/i18n-en";
import {i18nFr} from "./modules/i18n/i18n-fr";
import {i18nNl} from "./modules/i18n/i18n-nl";
import {HamoI18nModule} from "./modules/i18n/hamo-i18n.module";
import {HamoCommonModule} from "./modules/i18n/hamo-common/hamo-common.module";
import * as Sentry from "@sentry/angular";
import {LoginPageComponent} from "./pages/login-page/login-page.component";
import {HamoMenuComponent} from "./pages/hamo-menu/hamo-menu.component";


const initializer = (pwaInstallService: PwaInstallService) => () => pwaInstallService.initPwaPrompt();

const sentryInitializer = (whoamiService: WhoamiService) => () => {
  const promise = new Promise<void>((resolve, reject) => {
    whoamiService.apiWhoamiGet().subscribe(state => {
      if (state.isReady) {
        if (state.data?.authenticated && state.data!.sentryEnabled) {
          Sentry.init({
            dsn: state.data?.sentryDsn,
            defaultIntegrations: false,
            integrations: [
              Sentry.breadcrumbsIntegration(),
              Sentry.browserApiErrorsIntegration(),
              Sentry.functionToStringIntegration(),
              Sentry.globalHandlersIntegration(),
              Sentry.httpContextIntegration(),
              Sentry.inboundFiltersIntegration(),
              Sentry.httpClientIntegration(),
              Sentry.browserTracingIntegration()
            ],
            sendDefaultPii: true,
          });
          Sentry.setUser({
            email: state.data.email,
            id: state.data.id,
            username: state.data.name
          });
        }
        resolve();
      }
      if(state.isError){
        reject();
      }
    });
  });
  return promise;
};


export function i18nFactory() {
  return () => {
    return i18next.use(LanguageDetector).init({
      supportedLngs: LanguageService.langList,
      fallbackLng: 'en',
      returnEmptyString: false,
      ns: ['app'],
      resources: {
        en: {app: i18nEn},
        fr: {app: i18nFr},
        nl: {app: i18nNl}
      },
      interpolation: {
        // Whatever key requested will return the same answer
        defaultVariables: new Proxy({} as any, {
          get() {
            return '...';
          },
        }),
      },
      missingKeyHandler: (lngs, ns, key) => console.error('i18n key ' + key + ' is missing for ' + lngs.join(',')),
      saveMissing: true,
      detection: {
        // order and from where user language should be detected
        order: ['cookie'],

        // keys or params to lookup language fromter
        lookupCookie: 'lang',

        // cache user language on
        caches: ['cookie'],

        // optional expire and domain for set cookie
        cookieMinutes: 10080, // 7 days
      },
    });
  };
}


@NgModule({
  declarations: [
    AppComponent, LoginPageComponent, HamoMenuComponent
  ],
  imports: [BrowserModule, HamoCommonModule, HamoI18nModule, IonicModule.forRoot(), AppRoutingModule, ApiModule.forRoot(() => {
    return new Configuration({
      basePath: ``,
    });
  }), ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: false,
    // Register the ServiceWorker as soon as the application is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000'
  }),],
  providers: [provideHttpClient(), {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaInstallService], multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: i18nFactory,
      multi: true,
    },
    {provide: APP_INITIALIZER, useFactory: sentryInitializer, deps: [WhoamiService], multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
